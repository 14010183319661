<template>
    <section class="horizontal-slide horizontal-slide-cover" :style="{'left': $parent.getLeft(1) }">
      <div class="row">
        <div class="column hide-on-mobile"></div>
        <div class="column">
          <div class="numeral">{{$parent.gallery.meta.numeral}}</div>
          <h2>{{$parent.gallery.meta.title}}</h2>
          <h3>{{$parent.gallery.meta.location}}</h3>
        </div>
      </div>
    </section>
</template>

<script>

export default {
  name: 'Cover.vue',
  data () {
    return {

    }
  },
  props:{

  },
  mounted() {

  },
  computed:{

  },
  methods:{

  },
}
</script>

<style lang="scss" scoped>
    @import "@/globals.scss";
    .horizontal-slide-cover{
        .row{
            display: grid;
            grid-template-columns: 50vw 50vw;
            .column{
                box-sizing: border-box;
                &:last-child{
                    // width: 50vw;
                    background-color: #fff;
                    position: relative;
                    margin: 20px 20px;
                    padding: 50px;
                    width: calc(50vw - 40px);
                    height: calc(100vh - 40px);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
                    @media (hover: none) {
                        width: calc(100vw - 40px);
                    }
                    .numeral{
                        font:{
                            family: 'Montserrat';
                            size: 50px;
                            weight: 100;
                        }
                        line-height: 64px;
                        padding: 0px 20px 10px;
                        color: #666;
                        @media #{$mq-mobile-only} {
                            padding: 20px;
                        }
                    }
                    h2{
                        margin: 0 0 5px;
                        font:{
                            family: 'Nunito Sans', sans-serif;
                            size: 18px;
                            weight: 200;
                        }
                        letter-spacing: 3px;
                        text:{
                            transform: uppercase;
                            align: center;
                        }
                        color: #666;
                        @media #{$mq-mobile-only} {
                            font-size: 18px;
                        }
                    }
                    h3{
                        margin: 0;
                        font:{
                            family: 'Nunito Sans', sans-serif;
                            size: 14px;
                            weight: 400;
                        }
                        text:{
                            transform: uppercase;
                            align: center;
                        }
                        letter-spacing: 3px;
                        color: #999;
                        @media #{$mq-mobile-only} {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

</style>
