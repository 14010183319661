<template>
  <div>
    <footer>
      &copy; 2021 Stephen Knollenberg | Interior Designer<br>site by <a href="https://www.mplmnt.io" target="_blank">mplmnt</a>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "@/globals.scss";
  footer{
    position: fixed;
    @media (max-width: 600px) {
      position: static;
    }
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 20px;
    background-color: #f8f8f8;
    padding: 20px 0 20px 0;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    color: #666;
    text-align: center;
    letter-spacing: 1px;
    a{
      color: #000;
      text-decoration: none;
    }
  }
</style>
