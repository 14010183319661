<template>
  <div class="vertical-slider-shell">
    <div class="vertical-slider">
      <v-vslide v-for="(gallery, index) in $root.galleries" :gallery="gallery" :key="index" ref="vslide"></v-vslide>
      <v-vdots></v-vdots>
    </div>
    <a href="#" @click.prevent="goUp" :class="{'horizontal-slider-up': true, 'show': $root.mouseFollow.isTop, 'disabled': $root.verticalCurrent == 0 || $root.showGallery}"></a>
    <a href="#" @click.prevent="goDown" :class="{'horizontal-slider-down': true, 'show': $root.mouseFollow.isBottom, 'disabled': $root.verticalCurrent == $root.galleries.length-1 || $root.showGallery}"></a>
  </div>
</template>

<script>
export default {
  name: 'vertical-slider', 
  data () {
    return {
    }
  },
  methods:{
    getGalleryID(id){
      return this.$root.galleries[id].id;
    },
    goUp(){
      let nextID = this.$root.verticalCurrent - 1;
      if(nextID >= 0){
        this.$root.scrollToObject(this.getGalleryID(nextID));
      }
    },
    goDown(){
      let nextID = this.$root.verticalCurrent + 1;
      if(nextID <= this.$root.galleries.length - 1){
        this.$root.scrollToObject(this.getGalleryID(nextID));
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "@/globals.scss";
  .vertical-slider-shell{
    .vertical-slider{
      padding: 0;
      margin: 0;
      background-color: #000;
    }
    a{
      transition: all 1.0s ease-in-out;
      position: relative;
      &.horizontal-slider-up,
      &.horizontal-slider-down{
        opacity: 1;
        @media (hover: none) {
          display: none;
        }
        &.disabled{
          opacity: 0 !important;
        }
        z-index: 1000;
        position: fixed;
        left: 50%;
        margin-left: -20px;
        transition: opacity 0.5s linear;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 80px;
        &:before{
          content: '';
          width: 30px;
          height: 30px;
          opacity: 0;
          transition: all 0.6s linear;
          border-color: white !important;
          transform-origin: center;
        }
        &:after{
          content: '';
          width: 30px;
          height: 30px;
          opacity: 0;
          transition: all 0.6s linear;
          border-color: black !important;
          transform-origin: center;
          margin: 20px 0 0 0;
          filter: blur(0.3rem);
          position: absolute;
          z-index: -1;
        }
        &.show{
          &:before,
          &:after{
            opacity: 1;
          }
        }
      }
      &.horizontal-slider-up{
        top: 10px;
        &:before,
        &:after{
          border-right: 2px solid; 
          border-bottom: 2px solid;
          transform: rotate(225deg);
        }
      }
      &.horizontal-slider-down{
        bottom: 10px;
        &:before,
        &:after{
          border-right: 2px solid; 
          border-bottom: 2px solid;
          transform: rotate(45deg);
        }
      }
    }
  }

</style>
