<template>
  <div>
    <v-header></v-header>
    <div class="standard">
      <v-vslider></v-vslider>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Standard',
    data () {
      return {

      }
    },
  };
</script>

<style lang="scss">
  .standard{
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>