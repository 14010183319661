// Global components
import Vue from 'vue';

import HCover                 from './Cover.vue';
import HKey                   from './Key.vue';
import HNavigation            from './Navigation.vue';
import HNumeral               from './Numeral.vue';
import HSlider                from './Slider.vue';

Vue.component('v-hcover',        HCover);
Vue.component('v-hkey',          HKey);
Vue.component('v-hnavigation',   HNavigation);
Vue.component('v-hnumeral',      HNumeral);
Vue.component('v-hslider',       HSlider);
