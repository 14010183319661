<template>
    <section class="horizontal-slide horizontal-slide-numeral" :style="{'left': $parent.getLeft(0) }">
        <div class="numeral viewport-transiton" :style="{ color: `${$root.colors.numeral}`}">{{$parent.gallery.meta.numeral}}</div>
    </section>
</template>

<script>

export default {
  name: 'Numeral.vue',
  data () {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
    @import "@/globals.scss";
    .horizontal-slide-numeral{
        @media #{$mq-mobile-only} {
            justify-content: center;
            align-content: center;
        }
        .numeral{
            font:{
                family: 'Montserrat';
                size: 80px;
                weight: 100;
            }
            line-height: 64px;
            padding: 40px;
            text-shadow: 0px 0px 5px rgba(0,0,0,0.2);
        }
    }
</style>
