<template>
  <section class="horizontal-slider-navigation" v-touch:swipe.right="goBackward" v-touch:swipe.left="goForward">
    <a href="#" @click.prevent="closeGallery" :class="{'horizontal-slider-close': true, 'show': ($parent.horizontalCurrent > 1)}">
      <span></span>
      <span></span>
    </a>
    <a href="#" @click.prevent="goBackward" :class="{'horizontal-slider-back': true, 'show': $root.mouseFollow.isLeft, 'disabled': $parent.horizontalCurrent == 0}"></a>
    <a href="#" @click.prevent="goForward" :class="{'horizontal-slider-forward': true, 'show': $root.mouseFollow.isRight, 'disabled': $parent.horizontalCurrent === totalPhotos}"></a>
  </section>
</template>

<script>
export default {
  name: 'Navigation.vue',
  data () {
    return {
      action: false,
      width: 0,
      height: 0,
    }
  },
  computed:{
    totalPhotos(){
      return this.$parent.gallery.slides.length+1;
    },
  },
  created(){
    this.$root.mouseFollow.windowWidth = window.innerWidth;
    this.$root.mouseFollow.windowHeight = window.innerHeight;

    var bodyElement = document.querySelector("body");
    bodyElement.addEventListener("mousemove", this.cursorFinder, false);
  },
  methods:{
    cursorFinder(e) {
      let t =           this.$root.mouseFollow.threshold;
      let hP =          Math.floor((e.clientX/this.$root.mouseFollow.windowWidth)*100);
      let vP =          Math.floor((e.clientY/this.$root.mouseFollow.windowHeight)*100);

      this.$root.mouseFollow.isTop =    (vP <= t) ?        true : false;
      this.$root.mouseFollow.isBottom = (vP >= (100-t)) ?  true : false;
      this.$root.mouseFollow.isLeft =   (hP <= t) ?        true : false;
      this.$root.mouseFollow.isRight =  (hP >= (100-t)) ?  true : false;
    },
    closeGallery(){
      this.$root.showGallery = false;
    },
    goForward(){
      let that = this;
      if(this.$parent.horizontalCurrent >= this.totalPhotos){
        this.$parent.horizontalCurrent = 0;
        this.$root.showGallery = false;
        this.$parent.completed = true;
        setTimeout(function(){
          that.$parent.completed = false;
        },2000);
        return;
      }
      if(this.action){
        return;
      }else{
        this.action = true;
        this.$root.menuOpen = false;
        this.$root.showGallery = true;
        this.$root.scrollToObject(this.$root.gallerySelected);
        this.$parent.horizontalCurrent++;
        setTimeout(function(){that.action = false;},1300);
      }
    },
    goBackward(){
      let that = this;
      if(this.action){
        return;
      }else{
        if(this.$parent.horizontalCurrent > 0){
          this.action = true;
          this.$parent.horizontalCurrent--;
        }
        if(this.$parent.horizontalCurrent == 0){
          this.$root.showGallery = false;
        }
        setTimeout(function(){that.action = false;},1300);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
    @import "@/globals.scss";
    .horizontal-slider-navigation{
      cursor: default;
      a{
        transition: all 1.0s ease-in-out;
        position: relative;
        &.horizontal-slider-close{
          transition: opacity 0.5s linear;
          position: absolute;
          z-index: 10000;
          right: 40px;
          top: 40px;
          opacity: 0;
          @media #{$mq-tablet-only} {
            right: 20px;
            top: 20px;
          }
          @media #{$mq-mobile-only} {
            right: 20px;
            top: 20px;
          }
          &.show{
            opacity: 1;
          }
          &:hover{
            span{
              background-color: #efefef !important;
            }
          }
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          @media #{$mq-mobile-only} {
            width: 30px;
            height: 30px;
          }
          span{
            transition: all 0.2s linear;
            position: absolute;
            display: block;
            height: 2px;
            width: 100%;
            background-color: #fff;
            opacity: 1;
            &:nth-child(1) {
              transform: rotate(45deg);
            }
            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
        &.horizontal-slider-back,
        &.horizontal-slider-forward{
          opacity: 1;
          &.disabled{
            opacity: 0 !important;
          }
          z-index: 1000;
          position: absolute;
          top: 0;
          transition: opacity 0.5s linear;
          display: block;
          width: 20vw;
          height: 100vh;
          @media #{$mq-mobile-only} {
            width: 50vw;
          }
          &:before{
            opacity: 0;
            @media (hover: none) {
              opacity: 1;
            }
            transition: all 0.6s linear;
            content: '';
            position: absolute;
            z-index: 9999;
            top: 50%;
            margin-top: -15px;
            height: 30px;
            width: 30px;
            border-color: white !important;
            transform-origin: center;
            animation-duration: 0.8s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: ease-in-out;
            animation-play-state: paused;
          }
          &:after{
            content: '';
            width: 30px;
            height: 30px;
            opacity: 0;
            top: 48%;
            transition: all 0.6s linear;
            border-color: black !important;
            transform-origin: center;
            margin: 20px 0 0 0;
            filter: blur(0.3rem);
            position: absolute;
            z-index: -1;
          }
          &.show{
            &:before,
            &:after{
              opacity: 1;
            }
          }
        }
        &.horizontal-slider-back{
          left: 0;
          &:before,
          &:after{
            border-right: 2px solid; 
            border-bottom: 2px solid;
            transform: rotate(135deg);
            left: 30px;
            @media #{$mq-mobile-only} {
              left: 20px;
              animation: none;
            }
          }
          background: rgb(0,0,0);
          background: -moz-linear-gradient(90deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 50%);
          background: -webkit-linear-gradient(90deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 50%);
          background: linear-gradient(90deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 50%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
        }
        &.horizontal-slider-forward{
          right: 0;
          &:before,
          &:after{
            border-right: 2px solid; 
            border-bottom: 2px solid;
            transform: rotate(-45deg);
            right: 30px;
            // animation-name: bounceRight;
            @media #{$mq-mobile-only} {
              right: 20px;
              animation: none;
            }
          }
          background: rgb(0,0,0);
          background: -moz-linear-gradient(90deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.2) 100%);
          background: -webkit-linear-gradient(90deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.2) 100%);
          background: linear-gradient(90deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.2) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
        }
      }
    }
</style>
