<template>
  <div>
    <v-header></v-header>
    <div class="internal">
      <router-view></router-view>
    </div>
    <v-footer></v-footer>
  </div>
</template>

<script>
  export default {
    name: 'Light',
    created(){
      this.$root.colors.logo = '#000';
    }
  };
</script>

<style lang="scss" scoped>
  .internal{
    padding: 0px 20px;
    @media (max-width: 600px) {
      padding: 0px 20px;
    }
    .actions{
      position: absolute;
      left: 20px;
      top: 20px;
      a{
        color: #8f8f8f;
        font-size: 11px;
        letter-spacing: 1px;
        text:{
          transform: uppercase;
          decoration: none;
        }
        &:hover{
          color: #000;
        }
      }
      @media (max-width: 600px) {
        position: relative;
        left: 0px;
        right: 0px;
        text-align: center;
      }
    }
    .logo{
      text-align: center;
      padding: 50px 0 10px;
    }
  }

</style>