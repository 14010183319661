<template>
    <section :class="{'horizontal-slider-key': true, 'show': (showPageNumber)}">
      {{getCurrentPage}} of {{getTotal}}
    </section>
</template>

<script>

export default {
  name: 'Key.vue',
  data () {
    return {

    }
  },
  props:{

  },
  mounted() {

  },
  computed:{
    getCurrentPage(){
      return ((this.$parent.horizontalCurrent-1) < 0) ? 0 : this.$parent.horizontalCurrent-1;
    },
    getTotal(){
      return this.$parent.gallery.slides.length;
    },
    showPageNumber(){
      return this.$parent.horizontalCurrent > 1
    }
  },
  methods:{

  },
}
</script>

<style lang="scss" scoped>
    @import "@/globals.scss";
    .horizontal-slider-key{
      transition: opacity 0.5s linear;
      position: absolute;
      bottom: 10px;
      z-index: 1;
      left: 40px;
      right: 40px;
      text-align: right;
      color: #000;
      font:{
        size: 12px;
      }
      text-transform: uppercase;
      opacity: 0;
      @media #{$mq-mobile-only} {
        left: 20px;
        right: 20px;
      }
      &.show{
        opacity: 1;
      }
    }
</style>
