<template>
  <header>

    <a @click="toggle()" :class="{'hamburger': true, 'hide' : this.$root.showGallery, 'open': this.$root.menuOpen}">
      <div class="icon">
        <span :style="{ backgroundColor: `${$root.colors.menu}`}"></span>
        <span :style="{ backgroundColor: `${$root.colors.menu}`}"></span>
        <span :style="{ backgroundColor: `${$root.colors.menu}`}"></span>
      </div>
    </a>

    <a :href="logoLocation" @click="logoClick" :style="{ fill: `${$root.colors.logo}`}" class="viewport-transiton logo">
      <svg class="full" xmlns="http://www.w3.org/2000/svg" width="217.88775" height="8.18785" viewBox="0 0 217.88775 8.18785">
        <path d="M10.69854,16.34724a1.85561,1.85561,0,0,0,.74218.73682,2.18508,2.18508,0,0,0,1.04.24219,2.08215,2.08215,0,0,0,.68164-.10987,1.74449,1.74449,0,0,0,.55567-.3081,1.4588,1.4588,0,0,0,.374-.47852,1.41711,1.41711,0,0,0,.1377-.63281,1.21935,1.21935,0,0,0-.1709-.6709,1.36638,1.36638,0,0,0-.45606-.43457,3.42192,3.42192,0,0,0-.64355-.2915q-.35742-.12085-.73731-.2417-.37939-.12086-.7373-.2749a2.47618,2.47618,0,0,1-.64356-.40186,1.78626,1.78626,0,0,1-.456-.62158,2.2122,2.2122,0,0,1-.1709-.92383,2.00255,2.00255,0,0,1,.20409-.91845,1.95739,1.95739,0,0,1,.54982-.6709,2.441,2.441,0,0,1,.79686-.40723,3.2398,3.2398,0,0,1,.9463-.13721,3.06786,3.06786,0,0,1,1.18262.2251,2.244,2.244,0,0,1,.94043.75391l-.627.45068a1.84231,1.84231,0,0,0-.64356-.57715,1.86441,1.86441,0,0,0-.874-.19287,2.35954,2.35954,0,0,0-.6709.09375,1.67276,1.67276,0,0,0-.55567.2749,1.357,1.357,0,0,0-.37988.45655,1.38565,1.38565,0,0,0-.14258.64892,1.25257,1.25257,0,0,0,.29688.89649,2.09771,2.09771,0,0,0,.74219.49511,7.83492,7.83492,0,0,0,.96777.31885,4.72374,4.72374,0,0,1,.96875.36865,2.09,2.09,0,0,1,.74219.64356,1.91469,1.91469,0,0,1,.29687,1.13818,2.11307,2.11307,0,0,1-.19824.93506,2.053,2.053,0,0,1-.5332.6875,2.30825,2.30825,0,0,1-.77539.42383,2.94176,2.94176,0,0,1-.91309.14306,3.671,3.671,0,0,1-1.419-.26953,2.60251,2.60251,0,0,1-1.11132-.90771Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M22.512,10.66019H19.84991V10h6.05078v.66015H23.23854v7.128H22.512Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M31.27954,10.00008h4.7627v.66011H32.00518v2.772h3.78418v.66015H32.00518V17.128h4.21289v.66021H31.27954Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M41.98174,10.00008h2.24414a4.13946,4.13946,0,0,1,.85254.09375,2.48032,2.48032,0,0,1,.81445.32422,1.82623,1.82623,0,0,1,.60449.63281,1.95047,1.95047,0,0,1,.23632,1.00635,2.01291,2.01291,0,0,1-.19238.91308,1.78491,1.78491,0,0,1-.5166.63233,2.23315,2.23315,0,0,1-.748.36865,3.20991,3.20991,0,0,1-.88574.12109H42.7083v3.69583h-.72656Zm.72656,3.43211h1.64941a2.56732,2.56732,0,0,0,.60547-.07178,1.55738,1.55738,0,0,0,.52832-.231,1.20188,1.20188,0,0,0,.374-.42334,1.35374,1.35374,0,0,0,.14257-.64892,1.28878,1.28878,0,0,0-.14843-.63819,1.33729,1.33729,0,0,0-.38477-.43457,1.587,1.587,0,0,0-.54492-.24707,2.53648,2.53648,0,0,0-.61621-.07715H42.7083Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M52.47588,10.00008h.72657v3.43211h4.35546V10.00008h.72657v7.78811h-.72657v-3.6958H53.20244v3.6958h-.72657Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M64.59794,10.00008h4.7627v.66011h-4.0371v2.772h3.78418v.66015H65.32354V17.128h4.21289v.66021H64.59794Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M75.30106,10.00008h.93457l4.74121,6.82031h.02246V10.00008h.72559v7.78811h-.93457l-4.74121-6.81982h-.02246v6.81982h-.7256Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M95.0784,10.00008H95.805v7.78811H95.0784Z" transform="translate(-10.00509 -9.79884)" fill="#bc902d"/>
        <path d="M109.15847,10.00008h.72559v3.43211h.06641l3.77246-3.43213h1.001l-3.9375,3.564,4.1582,4.22412h-1.04492L109.9505,13.7623h-.06641v4.02589h-.72559Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M120.05886,10.00008h.93555l4.74121,6.82031h.02148V10.00008h.72656v7.78811h-.93554l-4.74121-6.81982h-.02149v6.81982h-.72656Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M132.29128,13.89412a4.42077,4.42077,0,0,1,.29688-1.63916,3.91693,3.91693,0,0,1,.82519-1.29248,3.75432,3.75432,0,0,1,1.27051-.85254,4.49234,4.49234,0,0,1,3.26756,0,3.76415,3.76415,0,0,1,1.27051.85254,3.899,3.899,0,0,1,.82422,1.29248,4.68853,4.68853,0,0,1,0,3.28369,3.879,3.879,0,0,1-.82422,1.29785,3.68806,3.68806,0,0,1-1.27051.84717,4.56,4.56,0,0,1-3.26756,0,3.679,3.679,0,0,1-1.27051-.84717,3.89661,3.89661,0,0,1-.82519-1.29785A4.46066,4.46066,0,0,1,132.29128,13.89412Zm.72656,0a3.87716,3.87716,0,0,0,.23047,1.353,3.2115,3.2115,0,0,0,.66016,1.08886,3.05419,3.05419,0,0,0,1.03906.72608,3.68272,3.68272,0,0,0,2.73924,0,3.0487,3.0487,0,0,0,1.04-.72608,3.19369,3.19369,0,0,0,.65918-1.08886,4.07068,4.07068,0,0,0,0-2.70606,3.19838,3.19838,0,0,0-.65918-1.08886,3.0487,3.0487,0,0,0-1.04-.72608,3.68272,3.68272,0,0,0-2.73924,0,3.054,3.054,0,0,0-1.03906.72608,3.21617,3.21617,0,0,0-.66016,1.08886A3.87749,3.87749,0,0,0,133.01784,13.89412Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M146.15163,10.00008h.72559V17.128h3.62988v.66019h-4.35545Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M155.83131,10.00008h.72656V17.128h3.62988v.66019h-4.35644Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M165.511,10.00008h4.76367v.66011h-4.03712v2.772h3.78416v.66015h-3.78416V17.128h4.21289v.66021H165.511Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M176.21412,10.00008h.93554l4.74122,6.82031h.02148V10.00008h.72656v7.78811h-.93554l-4.74122-6.81982h-.02148v6.81982h-.72656Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M188.95238,10.00008h2.24416a5.66216,5.66216,0,0,1,.89062.07177,2.11281,2.11281,0,0,1,.8086.29688,1.72092,1.72092,0,0,1,.583.627,2.16157,2.16157,0,0,1,.22559,1.06153,1.603,1.603,0,0,1-.35254,1.03418,1.72974,1.72974,0,0,1-1.04492.583v.022a2.08254,2.08254,0,0,1,.7041.18115,1.7206,1.7206,0,0,1,.9082.98486,2.1407,2.1407,0,0,1,.126.74756,2.07044,2.07044,0,0,1-.25879,1.09473,1.97844,1.97844,0,0,1-.64844.66552,2.49464,2.49464,0,0,1-.84765.33008,4.512,4.512,0,0,1-.85157.08789h-2.48633Zm.72559,3.36621h1.50684a2.54224,2.54224,0,0,0,.91308-.1377,1.58328,1.58328,0,0,0,.55078-.33545.9958.9958,0,0,0,.26367-.42334,1.56352,1.56352,0,0,0-.07128-1.08935,1.14237,1.14237,0,0,0-.36914-.42871,1.47239,1.47239,0,0,0-.53907-.22559,3.094,3.094,0,0,0-.64843-.06592H189.678Zm0,3.76171h1.66113a2.8518,2.8518,0,0,0,1.02832-.1538,1.56448,1.56448,0,0,0,.59961-.38526,1.196,1.196,0,0,0,.28028-.48926,1.76649,1.76649,0,0,0,.07226-.46728,1.54953,1.54953,0,0,0-.165-.7373,1.46425,1.46425,0,0,0-.42969-.50049,1.77675,1.77675,0,0,0-.59863-.28028,2.6724,2.6724,0,0,0-.67676-.08837H189.678Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M200.06176,10.00008h4.76367v.66011h-4.03709v2.772h3.78421v.66015h-3.78417V17.128h4.21288v.66021H200.0618Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M210.76488,10.00008h2.0459c.10352,0,.24219.0039.418.01123a4.2724,4.2724,0,0,1,.57227.06592,3.52108,3.52108,0,0,1,.627.17578,1.78622,1.78622,0,0,1,.57226.352,1.835,1.835,0,0,1,.418.583,2.00779,2.00779,0,0,1,.165.85791,1.969,1.969,0,0,1-.19238.92431,1.80234,1.80234,0,0,1-.48437.58838,2.0256,2.0256,0,0,1-.64356.33545,4.10337,4.10337,0,0,1-.6709.15381l2.26661,3.74023h-.8252l-2.18945-3.6958h-1.35254v3.69589H210.765Zm.72657,3.43213h1.54a2.547,2.547,0,0,0,.92969-.14307,1.53931,1.53931,0,0,0,.55468-.35205,1.10634,1.10634,0,0,0,.26954-.45069,1.37668,1.37668,0,0,0,0-.88037,1.10636,1.10636,0,0,0-.26954-.45068,1.53918,1.53918,0,0,0-.55468-.352,2.54724,2.54724,0,0,0-.92969-.14307h-1.54Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
        <path d="M227.89281,17.2823a5.05367,5.05367,0,0,1-1.41406.54443,6.99634,6.99634,0,0,1-1.49023.15967,4.2931,4.2931,0,0,1-1.63379-.30273,3.68789,3.68789,0,0,1-1.27049-.84717,3.87831,3.87831,0,0,1-.82422-1.29785,4.674,4.674,0,0,1,0-3.28369,3.89886,3.89886,0,0,1,.82422-1.29248,3.76407,3.76407,0,0,1,1.27049-.85254,4.23013,4.23013,0,0,1,1.63379-.30762A3.77059,3.77059,0,0,1,227.7932,10.88l-.52734.55029a2.28275,2.28275,0,0,0-.43457-.39062,2.96884,2.96884,0,0,0-.56152-.30811,3.6579,3.6579,0,0,0-.63184-.19775,3.05567,3.05567,0,0,0-.64941-.07178,3.41693,3.41693,0,0,0-1.36914.26416,3.04883,3.04883,0,0,0-1.04.72608,3.21615,3.21615,0,0,0-.66015,1.08886,4.0868,4.0868,0,0,0,0,2.70606,3.21147,3.21147,0,0,0,.66015,1.08886,3.0487,3.0487,0,0,0,1.04.72608,3.40843,3.40843,0,0,0,1.36914.26416,6.64568,6.64568,0,0,0,1.17675-.09912,3.0236,3.0236,0,0,0,1.001-.3628V14.24619h-1.87012V13.586h2.59668Z" transform="translate(-10.00509 -9.79884)" fill="inherit"/>
      </svg>
    </a>

    <nav :class="{'open': this.$root.menuOpen}">
      <ul>
        <li v-for="(item, index) in $root.menu" :key="index" :class="{'active': current(item.path)}">
          <a :href="item.path">{{item.label}}</a>
        </li>
        <li>
          <a href="https://www.instagram.com/stephenknollenberg/" target="_blank" class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
          </a>
        </li>
      </ul>
    </nav>

    <!-- <div :class="{'menu': true, 'hide' : this.$root.showGallery}">
      <div class="shell">
      </div>
    </div>
    <div class="row">
      <div class="column">
      </div>
    </div> -->
  </header>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      
    }
  },
  computed: {
    logoLocation(){
      return (this.$route.path == '/') ? 'javascript:void(0)' : '/';
    },
  },
  methods: {
    current(path){
      return (this.$route.path == path);
    },
    toggle() {
      this.$root.menuOpen = !this.$root.menuOpen;
    },
    logoClick(){
      if(this.$route.path == '/'){
        this.$root.showGallery = false;
        this.$root.gallerySelected = null;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "@/globals.scss";

  header{
    nav{
      &.open{
        opacity: 1;
        border-radius: 0%;
        transform: scale(1);
        top: 0;
        bottom: 0;
      }
      transition: all 0.75s cubic-bezier(0.640, 0.250, 0.385, 0.855);
      opacity: 0;
      background-color: rgba(0,0,0,0.75);
      position: fixed;
      width: 100vw;
      left: 0px;
      top: 40vh;
      bottom: 40vh;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      transform: scale(0);
      z-index: 1000;
      ul{
        list-style: none;
        padding: 5px 10px;
        display: flex;
        margin: 0;
        align-items: center;
        @media #{$mq-mobile-only} {
          flex-direction: column;
        }
        li{
          line-height: 1em;
          margin-bottom: 0px;
          padding: 0px;
          a{
            display: block;
            font:{
              family: 'Nunito Sans', sans-serif;
              size: 12px;
              weight: 700;
            }
            color: #fff;
            text:{
              decoration: none;
              transform: uppercase;
            }
            letter-spacing: 2px;
            padding: 0 20px;
            @media #{$mq-mobile-only} {
              padding: 10px 20px;
              text-align: center;
            }
            &:hover{
              color: #bc902d;
            }
            &.icon{
              svg{
                transition: all 0.75s ease-in-out;
                display: block;
                fill: rgba(255,255,255,0.8);
                width: 20px;
                height: 20px;
              }
              &:hover{
                svg{
                  fill: #bc902d;
                }
              }
            }
          }
        }
      }
    }

    .hamburger{
      transition: all 0.5s ease-in-out;
      opacity: 1;
      position: fixed;
      top: 40px;
      right: 40px;
      z-index: 1000;
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      @media #{$mq-mobile-only} {
        top: 20px;
        right: 20px;
      }
      &.hide{
        opacity: 0;
        z-index: 0;
      }
      &.open{
        z-index: 1001;
      }
      &.open{
        .icon{
          span{
            top: 12px !important;
            &:nth-child(1) {
              animation-name: turnTop;
              transform: rotate(45deg);
            }
            &:nth-child(2) {
              animation-name: turnBottom;
              transform: rotate(-45deg);
            }
            &:nth-child(3) {
              opacity: 0;
            }
          }
        }
      }
      .icon{
        transform: rotate(0deg);
        transition: all 0.5s ease-in-out;
        cursor: pointer;
        width: 24px;
        height: 24px;
        position: relative;
        span{
          transition: all 0.2s ease-in-out;
          transform: rotate(0deg);
          transform-origin: center;
          animation-duration: 0.5s;
          animation-iteration-count: 1;
          display: block;
          position: absolute;
          height: 2px;
          width: 100%;
          background-color: #fff;
          opacity: 1;
          &:nth-child(1) {
            animation-name: straightenTop;
            top: 4px;
          }
          &:nth-child(2){
            animation-name: straightenBottom;
            top: 20px;
          }
          &:nth-child(3){
            top: 12px;
          }
        }
      }
    }

    .logo{
      transition: all 0.5s ease-in-out;
      opacity: 1;
      position: fixed;
      top: 40px;
      left: 40px;
      z-index: 1000;
      @media #{$mq-mobile-only} {
        top: 20px;
        left: 20px;
      }
      svg{
        &.full{
          width: 320px;
          height: auto;
          @media #{$mq-mobile-only} {
            width: 200px;
            margin-left: 0px;
          }
        }
      }
    }


    // &.open{
    //   .menu{
    //     .shell{
    //       nav{
    //         opacity: 1;
    //         border-radius: 0%;
    //         transform: scale(1);
    //         top: 0;
    //         bottom: 0;
    //       }
    //     }
    //   }
    // }
    .menu{
      transition: opacity 1s ease-in-out;
      opacity: 1;
      order: 2;
      &.hide{
        opacity: 0;
        z-index: -1;
      }
      .shell{
        position: relative;
        display: flex;
        height: 100%;
        justify-content: flex-end;
      }
    }
    // .row{
    //   display: flex;
    //   flex-direction: row;
    //   order: 1;
    //   >.column{
    //     @media #{$mq-mobile-only} {
    //       text-align: left;
    //     }
    //     a{
    //       display: block;
    //     }
    //   }
    // }
  }
</style>
