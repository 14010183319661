<template>
  <div :class="['horizontal-slider', (this.completed ? 'horizontal-slider-completed' : '')]" :style="{'left': leftOffset}">
    
    <v-hnavigation></v-hnavigation>
    <v-hkey></v-hkey>
    <v-hnumeral></v-hnumeral>
    <v-hcover></v-hcover>
    
    <section class="horizontal-slide horizontal-slide-photo" :style="{'transform': 'translateX('+getLeft(sid+2)+')'}" v-for="(slide, sid) in $parent.gallery.slides" :key="sid">
      <div 
        v-for="(photo, pid) in slide.photos" 
        :key="pid" 
        :style="styles(photo)" 
        :class="classes(photo)"
        >
        <img ref="imageLoad" :src="thumbUrl(photo)" />
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: 'Slider.vue',
  data () {
    return {
      horizontalCurrent: 0,
      completed: false,
      inViewClass: 'not-in-view',
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      allowMove: false,
    }
  },
  props:{
    gallery:{
      type: Object
    },
  },
  async mounted() {
    this.imageWidth = this.$refs.imageLoad.width;
    this.imageHeight = this.$refs.imageLoad.height;
  },
  computed:{
    leftOffset(){
      return ((this.horizontalCurrent*100)) + 'vw';
    },
  },
  methods:{
    styles(photo){
      let styleReturn = [];
      styleReturn.push("background-image: url('"+this.thumbUrl(photo)+"');");
      if(photo.anchor){
        if(photo.anchor.vertical){
          styleReturn.push("background-position-x: "+photo.anchor.vertical+";");
        }
        if(photo.anchor.vertical){
          styleReturn.push("background-position-y:"+photo.anchor.vertical+";");
        }
      }
      return styleReturn.join(' ');
    },
    classes(photo,format){
      let classesReturn = [];
      classesReturn.push('viewport-observer');
      if(photo.style){
        classesReturn.push(photo.style);
      }
      if(photo.format){
        classesReturn.push(photo.format);
      }
      if(photo.anchor){
        if(photo.anchor.vertical){
          classesReturn.push(photo.anchor.vertical);
        }
        if(photo.anchor.horizontal){
          classesReturn.push(photo.anchor.horizontal);
        }
      }
      if(format){
        classesReturn.push(format);
      }
      return classesReturn.join(' ');
    },
    getLeft(num){
      return ((num-this.horizontalCurrent)*100)+'%';
    },
    thumbUrl(photo) {
      let path = this.gallery.meta.directory + photo.file;
      return require("@/" + path);
    },
  },
  watch:{
    '$root.showGallery' (to){
      if(to == false){
        this.horizontalCurrent = 0;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/globals.scss";

  .horizontal-slider{
    transition: all 1.3s ease-in-out;
    &.horizontal-slider-completed{
      .horizontal-slide{
        opacity: 0;
      }
    }
    .horizontal-slide{
      transition: all 1.3s ease-in-out, opacity 0.5s linear;
      width: 100vw;
      height: 100vh;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 1;
      z-index: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-end;
      cursor: default;
      &.horizontal-slide-photo{
        background: rgb(244,244,244);
        background: -moz-linear-gradient(180deg, rgba(244,244,244,1) 0%, rgba(235,234,232,1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(244,244,244,1) 0%, rgba(235,234,232,1) 100%);
        background: linear-gradient(180deg, rgba(244,244,244,1) 0%, rgba(235,234,232,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f4f4f4",endColorstr="#ebeae8",GradientType=1);
        .viewport-observer{
          transition: all 0.5s linear;
          width: 100%;
          height: 100%;
          @media #{$mq-tablet-only} {
            border-width: 20px;
          }
          @media #{$mq-mobile-only} {
            border-width: 10px;
          }
          box-sizing: border-box;
          background: {
            position: center;
            size: cover;
            repeat: no-repeat;
          }
          @media #{$mq-tablet-only} {
            background: {
              position: center !important;
            }
          }
          &.right{
            background: {
              position-x: right !important;
            }
          }
          &.right-half{
            background: {
              position-x: 75% !important;
            }
          }
          &.left{
            background: {
              position-x: left !important;
            }
          }
          &.left-half{
            background: {
              position-x: 25% !important;
            }
          }
          &.floating{
            @media #{$mq-tablet-up} {
              border: solid 40px transparent;
              background: {
                size: contain;
              }
            }
          }
          &:first-child:not(:last-child),
          &:last-child:not(:first-child){
            width: 50%;
            height: 100%;
          }
          > img {
            opacity: 0;
            grid-row: 1 / -1;
            grid-column: 1;
            max-width: 100%;
            height: auto;
            display: none;
          }
          &.boxed{
            border: solid 40px #fff;
            @media #{$mq-tablet-only} {
              border-width: 20px;
            }
            @media #{$mq-mobile-only} {
              border-width: 10px;
            }
          }
        }
      }
      &.horizontal-slide-final{
        background-color: #fff;
        opacity: 1;
        &.horizontal-slide-completed{
          opacity: 0;
        }
      }
    }
  }
</style>
