import Vue              from 'vue';
import App              from '@/App.vue'
import router           from '@/router';
import Vue2TouchEvents  from 'vue2-touch-events'
import Hotjar from 'vue-hotjar'

Vue.use (Hotjar, {
  id: '3092326' // Hotjar Site ID
})
Vue.use(Vue2TouchEvents);
Vue.config.productionTip = false;

import './components';

new Vue({
  router,
  data () {
    return {
      arrowStyles:{
        top: '0px',
        left: '0px',
      },
      mouseFollow:{
        windowWidth: 0,
        windowHeight: 0,
        threshold: 30,
        isTop: false,
        isBottom: false,
        isLeft: false,
        isRight: false,
      },
      modalOpen: false,
      menuOpen: false,
      verticalCurrent: 0,
      showGallery: false,
      disableWheelScroll: false,
      gallerySelected: 0,
      colors:{},
      general:{
        name: 'STEPHEN KNOLLENBERG',
        www: 'https://www.stephenknollenberg.com/',
      },
      menu:[
        {id: 'about', label:'About', path: '/about'},
        {id: 'contact', label:'Contact', path: '/contact'},
        {id: 'credits', label:'Credits', path: '/credits'},
      ],
      galleriesMobile:[
        {
          index: 0,
          id:'florida-retreat',
          meta:{
            numeral: 'I',
            title: 'Florida Retreat',
            location: 'Naples, Florida',
            directory: 'galleries/florida-retreat/',
          },
          colors: {
            numeral: '#bc902d',
            menu: '#bc902d',
            logo: '#fff',
            active: '#bc902d',
            gallery: '#ffffff',
          },
          hero:{
            file: 'assets/home/image-01.jpg',
            position: 'bottom center', 
          },
          slides: [
            {
              photos:[
                {
                  file: '1.0.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '1.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '2.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '3.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '4.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '5.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '6.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '7.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '8.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '9.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '11.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '12.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '13.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '14.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '15.jpg',
                },
              ],
            },
          ]
        },
        {
          index: 1,
          id:'michigan-estate',
          meta:{
            numeral: 'II',
            title: 'Michigan Estate',
            location: 'Bloomfield Hills, Michigan',
            directory: 'galleries/michigan-estate/',
          },
          colors: {
            numeral: '#fff',
            menu: '#fff',
            logo: '#fff',
            active: '#000',
            gallery: '#fff',
          },
          hero:{
            file: 'assets/home/image-02.jpg',
            position: 'top center',
          },
          slides: [
            {
              photos:[
                {
                  file: '2.jpg',
                },
              ],            
            },
            {
              photos:[
                {
                  file: '3.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '4.jpg',
                },
              ],            
            },
            {
              photos:[
                {
                  file: '5.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '6.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '7.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '8.jpg',
                },
              ],            
            },
            {
              photos:[
                {
                  file: '9.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '10.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '11.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '12.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '13.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '14.jpg',
                },
              ],
            }
          ]
        },
        {
          index: 2,
          id:'residence',
          meta:{
            numeral: 'III',
            title: 'Residence, The Mark Hotel',
            location: 'New York, New York',
            directory: 'galleries/residence/',
          },
          colors: {
            numeral: '#ae130a',
            menu: '#ae130a',
            logo: '#000',
            active: '#ae130a',
            gallery: '#fff',
          },
          hero:{
            file: 'assets/home/image-03.jpg',
            position: 'top center',
          },
          slides: [
            {
              photos:[
                {
                  file: '2.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '3.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '4.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '5.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '6.jpg',
                  anchor: {
                    horizontal: 'right-half',
                  },
                },
              ],
            },
            {
              photos:[
                {
                  file: '7.jpg',
                  anchor: {
                    horizontal: 'right',
                  },
                },
              ],
            },
            {
              photos:[
                {
                  file: '8.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '9.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '10.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '11-alt.jpg',
                },
              ],
            },
          ]
        },
        {
          index: 3,
          id:'collectors-townhouse',
          meta:{
            numeral: 'IV',
            title: 'Collector\'s Townhouse',
            location: 'Bloomfield Hills, Michigan',
            directory: 'galleries/collectors-townhouse/',
          },
          colors: {
            numeral: '#fff',
            menu: '#fff',
            logo: '#fff',
            active: '#2b241c',
            gallery: '#fff',
          },
          hero:{
            file: 'assets/home/image-04.jpg',
            position: 'bottom center',
          },
          slides: [
            {
              photos:[
                {
                  file: '1.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '2.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '4.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '5.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '6.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '7.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '8.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '9.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '10.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '11.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '12.jpg',
                },
              ],
            }
          ],
        },
        {
          index: 4,
          id:'key-west-contemporary',
          meta:{
            numeral: 'V',
            title: 'Key West Contemporary',
            location: 'Key West, Florida',
            directory: 'galleries/key-west-contemporary/',
          },
          colors: {
            numeral: '#000',
            menu: '#000',
            logo: '#000',
            active: '#000',
            gallery: '#fff',
          },
          hero:{
            file: 'assets/home/image-05.jpg',
            position: 'center center',
          },
          slides: [
            {
              photos:[
                {
                  file: '2.jpg',
                  anchor: {
                    horizontal: 'right',
                  },
                },
              ],
            },
            {
              photos:[
                {
                  file: '3.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '4.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '6.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '7.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '8.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '9.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '10.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '11.jpg',
                },
              ],
            }
          ]
        },
        {
          index: 5,
          id:'gramercy-park-loft',
          meta:{
            numeral: 'VI',
            title: 'Gramercy Park Loft',
            location: 'New York, New York',
            directory: 'galleries/gramercy-park-loft/',
          },
          colors: {
            numeral: '#fff',
            menu: '#fff',
            logo: '#000',
            active: '#000',
            gallery: '#fff',
          },
          hero:{
            file: 'assets/home/image-06.jpg',
            position: 'top center',
          },
          slides: [
            {
              photos:[
                {
                  file: '5.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '1.jpg',
                },
              ],            
            },
            {
              photos:[
                {
                  file: '2.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '3.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '4.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '7.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '8.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '9.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '10.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '11.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '12.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '13.jpg',
                },
              ],
            },
          ]
        },
        {
          index: 6,
          id:'kennedy-center-showhouse',
          meta:{
            numeral: 'VII',
            title: 'Kennedy Center Showhouse',
            location: 'Washington, DC',
            directory: 'galleries/kennedy-center-showhouse/',
          },
          colors: {
            numeral: '#000',
            menu: '#000',
            logo: '#000',
            active: '#000',
            gallery: '#fff',
          },
          hero:{
            file: 'assets/home/image-07.jpg',
            position: 'center center',
          },
          slides: [
            {
              photos:[
                {
                  file: '2.jpg',
                },
              ],            
            },
            {
              photos:[
                {
                  file: '3.jpg',
                },
              ],            
            },
            {
              photos:[
                {
                  file: '4.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '5.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '6.jpg',
                },
              ],            
            }
          ]
        },
      ],
      galleriesDesktop:[
        {
          index: 0,
          id:'florida-retreat',
          meta:{
            numeral: 'I',
            title: 'Florida Retreat',
            location: 'Naples, Florida',
            directory: 'galleries/florida-retreat/',
          },
          colors: {
            numeral: '#bc902d',
            menu: '#bc902d',
            logo: '#fff',
            active: '#bc902d',
            gallery: '#ffffff',
          },
          hero:{
            file: 'assets/home/image-01.jpg',
            position: 'bottom center', 
          },
          slides: [
            {
              photos:[
                {
                  file: '1.0.jpg',
                },
                {
                  file: '10.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '1.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '3.0.jpg',
                  style: 'boxed',
                  // anchor: {
                  //   vertical: 'bottom',
                  // },
                },
                {
                  file: '6.jpg',
                  anchor: {
                    vertical: 'bottom',
                  },
                },
              ],
            },
            {
              photos:[
                {
                  file: '2.jpg',
                },
                {
                  file: '3.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '4.jpg',
                },
                {
                  file: '5.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '7.jpg',
                },
                {
                  file: '8.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '9.jpg',
                  anchor: {
                    vertical: 'top',
                  },
                },
              ],
            },
            {
              photos:[
                {
                  file: '11.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '12.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '13.jpg',
                },
                {
                  file: '15.jpg',
                  style: 'boxed',
                },
              ],
            },
          ]
        },
        {
          index: 1,
          id:'michigan-estate',
          meta:{
            numeral: 'II',
            title: 'Michigan Estate',
            location: 'Bloomfield Hills, Michigan',
            directory: 'galleries/michigan-estate/',
          },
          colors: {
            numeral: '#fff',
            menu: '#fff',
            logo: '#fff',
            active: '#000',
            gallery: '#fff',
          },
          hero:{
            file: 'assets/home/image-02.jpg',
            position: 'top center',
          },
          slides: [
            {
              photos:[
                {
                  file: '1.jpg',
                },
              ],            
            },
            {
              photos:[
                {
                  file: '2.jpg',
                },
              ],            
            },
            {
              photos:[
                {
                  file: '4.jpg',
                },
              ],            
            },
            {
              photos:[
                {
                  file: '5.jpg',
                },
                {
                  file: '7.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '6.jpg',
                },
                {
                  file: '8.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '9.jpg',
                },
                {
                  file: '3.jpg',
                  style: 'boxed',
                  anchor: {
                    vertical: 'bottom',
                  },
                },
              ],
            },
            {
              photos:[
                {
                  file: '10.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '11.jpg',
                },
                {
                  file: '12.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '13.jpg',
                },
                {
                  file: '14.jpg',
                  style: 'boxed',
                },
              ],
            }
          ]
        },
        {
          index: 2,
          id:'residence',
          meta:{
            numeral: 'III',
            title: 'Residence, The Mark Hotel',
            location: 'New York, New York',
            directory: 'galleries/residence/',
          },
          colors: {
            numeral: '#ec1d2c',
            menu: '#fff',
            logo: '#000',
            active: '#ec1d2c',
            gallery: '#fff',
          },
          hero:{
            file: 'assets/home/image-03.jpg',
            position: 'top center',
          },
          slides: [
            {
              photos:[
                {
                  file: '1.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '2.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '3.jpg',
                },
                {
                  file: '10.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '4.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '5.jpg',
                },
                {
                  file: '7.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '6.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '8.jpg',
                },
                {
                  file: '9.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '11.jpg',
                },
              ],
            },
          ]
        },
        {
          index: 3,
          id:'collectors-townhouse',
          meta:{
            numeral: 'IV',
            title: 'Collector\'s Townhouse',
            location: 'Bloomfield Hills, Michigan',
            directory: 'galleries/collectors-townhouse/',
          },
          colors: {
            numeral: '#fff',
            menu: '#fff',
            logo: '#fff',
            active: '#2b241c',
            gallery: '#fff',
          },
          hero:{
            file: 'assets/home/image-04.jpg',
            position: 'bottom center',
          },
          slides: [
            {
              photos:[
                {
                  file: '3.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '1.jpg',
                },
                {
                  file: '4.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '2.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '5.jpg',
                },
                {
                  file: '8.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '6.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '9.jpg',
                },
                {
                  file: '7.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '11.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '10.jpg',
                },
                {
                  file: '12.jpg',
                  style: 'boxed',
                },
              ],
            }
          ],
        },
        {
          index: 4,
          id:'key-west-contemporary',
          meta:{
            numeral: 'V',
            title: 'Key West Contemporary',
            location: 'Key West, Florida',
            directory: 'galleries/key-west-contemporary/',
          },
          colors: {
            numeral: '#63980f',
            menu: '#000',
            logo: '#000',
            active: '#71b606',
            gallery: '#fff',
          },
          hero:{
            file: 'assets/home/image-05.jpg',
            position: 'center center',
          },
          slides: [
            {
              photos:[
                {
                  file: '1.jpg',
                },
                {
                  file: '4.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '2.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '3.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '5.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '6.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '7.jpg',
                },
                {
                  file: '8.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '9.jpg',
                },
                {
                  file: '10.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '11.jpg',
                },
              ],
            }
          ]
        },
        {
          index: 5,
          id:'gramercy-park-loft',
          meta:{
            numeral: 'VI',
            title: 'Gramercy Park Loft',
            location: 'New York, New York',
            directory: 'galleries/gramercy-park-loft/',
          },
          colors: {
            numeral: '#fff',
            menu: '#fff',
            logo: '#000',
            active: '#000',
            gallery: '#fff',
          },
          hero:{
            file: 'assets/home/image-06.jpg',
            position: 'top center',
          },
          slides: [
            {
              photos:[
                {
                  file: '6.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '5.jpg',
                  anchor: { 
                    vertical: 'bottom',
                  },
                },
              ],
            },
            {
              photos:[
                {
                  file: '1.jpg',
                },
                {
                  file: '7.jpg',
                  style: 'boxed',
                },
              ],            
            },
            {
              photos:[
                {
                  file: '2.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '3.jpg',
                  style: 'boxed',
                },
                {
                  file: '4.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '8.jpg',
                },
                {
                  file: '10.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '9.jpg',
                },
              ],
            },
            {
              photos:[
                {
                  file: '11.jpg',
                },
                {
                  file: '12.jpg',
                  style: 'boxed',
                },
              ],
            },
          ]
        },
        {
          index: 6,
          id:'kennedy-center-showhouse',
          meta:{
            numeral: 'VII',
            title: 'Kennedy Center Showhouse',
            location: 'Washington, DC',
            directory: 'galleries/kennedy-center-showhouse/',
          },
          colors: {
            numeral: '#000',
            menu: '#000',
            logo: '#000',
            active: '#000',
            gallery: '#fff',
          },
          hero:{
            file: 'assets/home/image-07.jpg',
            position: 'center center',
          },
          slides: [
            {
              photos:[
                {
                  file: '1.jpg',
                },
                {
                  file: '2.jpg',
                  style: 'boxed',
                },
              ],            
            },
            {
              photos:[
                {
                  file: '3.jpg',
                },
              ],            
            },
            {
              photos:[
                {
                  file: '4.jpg',
                },
                {
                  file: '5.jpg',
                  style: 'boxed',
                },
              ],
            },
            {
              photos:[
                {
                  file: '6.jpg',
                },
              ],            
            }
          ]
        },
      ],
    }
  },
  render: h => h(App),
  created(){
    let self = this;
    this.colors = this.galleries[this.verticalCurrent].colors;
    window.addEventListener("wheel", function(e) {
      if(self.disableWheelScroll){
        e.preventDefault();
      }
    }, {
      passive: false
    });
  },
  computed:{
    galleries(){
      let x = window.matchMedia("(max-width: 600px)");
      return (x.matches) ? this.galleriesMobile : this.galleriesDesktop;
    },
    appClass(){
      let classes = [];
      try{
        let name = this.$route.name;
        classes.push(name.toLowerCase());
        if(this.$route.meta.mode){
          classes.push('template-'+this.$route.meta.mode.toLowerCase());
        }
      }catch(e){
        classes.push('error');
      }
      return classes.join(' ');
    },
  },
  methods:{
    scrollToObject(id){
      let self = this;
      this.disableWheelScroll = true;
      document.querySelector('#'+id).scrollIntoView();
      setTimeout(function(){
        self.disableWheelScroll = false;
      },2000);
    },
    goToGallery(id){
      let self = this;
      this.disableWheelScroll = true;
      document.querySelector('#'+id).scrollIntoView();
      setTimeout(function(){
        self.disableWheelScroll = false;
      },1000);
    },
  },
  watch:{
    'verticalCurrent' (to){
      this.colors = this.galleries[to].colors;
    },
    'showGallery' (to){
      if(to){
        document.documentElement.style.scrollSnapType = "none";
        document.body.style.overflow = "hidden";
      }else{
        document.documentElement.style.removeProperty("scroll-snap-type");
        document.body.style.removeProperty("overflow");
      }
    }
  }
}).$mount('#app')