// Global components
import Vue from 'vue';

import VSlide                from './Slide.vue';
import VSlider               from './Slider.vue';
import VDots                 from './Dots.vue';

Vue.component('v-vslide',    VSlide);
Vue.component('v-vslider',   VSlider);
Vue.component('v-vdots',     VDots);
