<template>
  <div class="content">
    <h1>CONTACT</h1>
    <p>327 SOUTHFIELD ROAD, IBN<br>BIRMINGHAM, MICHIGAN 48009</p>
    <p>222 E.17TH STREET, 2F<br>NEW YORK, NY 10003</p>
    <p>PHONE: 248.203.1082</p>
    <p>E-MAIL: <a href="mailto:smk@stephenknollenberg.com" target="_blank">smk@stephenknollenberg.com</a></p>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {

    }
  },
  created() {

  }
}
</script>

<style lang="scss" scoped>

</style>
