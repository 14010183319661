<template>
  <div class="modal" @click="close()">
    <img :src="image" :data-aspect-ratio="aspectRatio">
  </div>
</template>

<script>
export default {
  name: 'Modal',
  data () {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      imageWidth: 0,
      imageHeight: 0,
    }
  },
  props:{
    image:{
      type: String,
    },
  },
  async mounted(){
    this.imageLoad();
  },
  created(){
    let self = this;
    document.addEventListener('keyup', function (evt) {
      if (evt.keyCode === 27) {
        self.close();
      }
    });
  },
  computed:{
    orientation(){
      return (this.imageWidth > this.imageHeight) ? 'l' : 'p';
    },
    aspectRatio(){
      let ratio = (this.imageWidth > this.imageHeight) ? (this.imageHeight/this.imageWidth) : (this.imageWidth/this.imageHeight);
      return (ratio*100).toFixed(0);
    },
  },
  methods:{
    imageLoad(){
      let self = this;
      let tmpImage = new Image();
      tmpImage.onload = function () {
        self.imageWidth = this.width;
        self.imageHeight = this.height;
      };
      tmpImage.src = this.image
    },
    close(){
      this.$root.modalOpen = false;
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "@/globals.scss";
  
  .modal{
    transition: background-position 2s linear, left 1s ease-in-out;
    background:{
      color: rgba(0,0,0,0.6);
      size: 200%;
    }
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 100vw;
    right: 0px;
    z-index: 210;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-content: center;
    @media #{$mq-mobile-only} {
      // display: block;
    }
    &:before{
      content: '';
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background:{
        color: rgba(255,255,255,0.6);
      }
      z-index: 1;
    }
    .close{
      position: absolute;
      z-index: 7;
      top: 12px;
      right: 20px;
      svg{
        width: 30px;
        height: 30px;
      }
    }
    &.open{
      left: 0px;
    }
    img{
      display: block;
      position: relative;
      align-self: center;
      justify-self: center;
      max-width: 90%;
      max-height: 90%;
      z-index: 5;
      box-shadow: 0px 0px 40px rgba(0,0,0,0.3);
      @media #{$mq-mobile-only} {
        box-shadow: none;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
</style>
