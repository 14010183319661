<template>
  <div class="content">
    <h1>About the Designer</h1>
    <img :src="require(`@/assets/stephen-knollenberg.jpg`)" />
    <p>Stephen Knollenberg has been an interior designer since 1996. He designs both traditional and contemporary interiors often combining elements of each. Innovation, editing and style are basic tenets of his work and his designs are at home in both town and country.</p>
    <p>When the best of design, old and new, has been carefully curated timeless and elegant interiors are the result. Stephen's environments have visceral appeal. "There is a sweet spot in a composition where balance, color and form all come together". Stephen's clean and collected rooms have been the choice for a discerning clientele for over two decades.</p>
    <p>Stephen Knollenberg has appeared in Architectural Digest ("Designers Own Homes", "Key West Contemporary"), The New York Times, The Washington Post, Meredith Books and 1st Dibs Introspective Magazine.</p>
    <p>Stephen has been named to Architectural Digest's AD100 list of top interior designers and architects.</p>
    <br>
    <h4>Related Links</h4>
    <ul>
      <li><a href="https://www.architecturaldigest.com/story/knollenberg-article" target=_blank>Designers Own Homes</a></li>
      <li><a href="https://www.architecturaldigest.com/story/key-west-article" target=_blank>Key West Contemporary</a></li>
      <li><a href="https://www.1stdibs.com/introspective-magazine/stephen-knollenberg/" target=_blank>1st Dibs Introspective Magazine</a></li>
      <li><a href="https://www.architecturaldigest.com/story/stephen-knollenberg-profile" target=_blank>Architectural Digest's AD100 list</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      current: 0,
    }
  },
  computed: {
    x(){
      return (this.current*-400) + 'px';
    }
  },
  methods:{
    prev(){
      this.current = 0;
    },
    next(){
      this.current = 1;
    },
  }
}
</script>

<style lang="scss" scoped>
  img{
    float: left;
    max-width: 150px;
    margin-right: 15px;
    margin-bottom: 10px;
  }
  p{
    margin-top: 0;
  }
  h4{
    margin-bottom: 0;;
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
  }
</style>
