<template>
  <div :class="['vertical-slider-dots', hideDots]">
    <ul>
      <li v-for="(gallery, index) in $root.galleries" :key="index" :class="{'active': isActive(index)}">
        <a :href="`#`+gallery.id" @click="scrollTo(gallery.id)" v-if="isActive(index)" :data-name="gallery.meta.title" class="viewport-transiton">
          &bullet;
        </a>
        <a :href="`#`+gallery.id" @click="scrollTo(gallery.id)" :data-name="gallery.meta.title" v-else>
          &bullet;
        </a>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'v-dots',
  data () {
    return {
      inViewClass: 'not-active',
    }
  },
  methods:{
    thumbUrl(filename) {
      return require("@/" + filename);
    },
    isActive(i){
      return (i === this.$root.verticalCurrent);
    },
    scrollTo(id){
      this.$root.scrollToObject(id);
    }
  },
  computed:{
    hideDots(){
      return this.$root.showGallery ? 'hide' : '';
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/globals.scss";

  .vertical-slider-dots{
    transition: opacity 1s ease-in-out;
    position: fixed;
    opacity: 1;
    bottom: 0px;
    right: 0px;
    width: 20px;
    display: flex;
    align-items: flex-end;
    z-index: 210;
    cursor: auto;
    padding: 20px;
    @media #{$mq-mobile-only} {
      display: none;
    }
    &.hide{
      opacity: 0;
    }
    ul{
      padding: 0;
      margin: 0;
      list-style: none;
      li{
        a{
          color: #fff;
          text-shadow: 0px 0px 3px rgba(0,0,0,0.4);
          text-decoration: none;
          white-space: nowrap;
          &:before{
            transition: opacity 0.5s linear;
            opacity: 0;
            position: absolute;
            content: attr(data-name);
            z-index: -1;
            right: 24px;
            background-color: rgba(0,0,0,0.6);
            border-radius: 25px;
            color: #fff;
            padding: 5px 30px 5px 19px;
            font:{
              family: 'Nunito Sans', sans-serif;
              size: 10px;
            }
            letter-spacing: 4px;
            text-transform: uppercase;
            text-align:center;
          }
          &:hover{
            color: #bc902d;
            &:before{
              opacity: 1;
            }
          }
        }
        &.active{
          a{
            color: #000;
          }
        }
      }
    }
  }
</style>
