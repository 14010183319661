<template>
  <div class="content">
    <h1>CREDITS</h1>
    <p>
      <b>PORTRAIT OF STEPHEN KNOLLENBERG BY</b><br>
      LISA SPINDLER
    </p>
    <p>
      <b>LOCATION PHOTOGRAPHY BY</b><br>
      ANTIONE BOOTZ<br>
      JAMES HAEFNER<br>
      BETH SINGER<br>
      GORDON BEALL<br>
      DANA HOFF
    </p>
  </div>
</template>

<script>
export default {
  name: 'Credits',
  data () {
    return {

    }
  },
  created() {

  }
}
</script>

<style lang="scss" scoped>

</style>
