// Global components
import Vue from 'vue';

import './vertical-slider';
import './horizontal-slider';

// APP Level
import Header               from './Header.vue';
import Footer               from './Footer.vue';
import Modal                from './Modal.vue';

Vue.component('v-header',           Header);
Vue.component('v-footer',           Footer);
Vue.component('v-modal',            Modal);
