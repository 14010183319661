<template>
  <div :class="classes" :style="styles" :id="gallery.id">
    <v-hslider :gallery="gallery"></v-hslider>
  </div>
</template>

<script>

export default {
  name: 'vertical-slide',
  data () {
    return {
      inViewClass: 'not-active',
    }
  },
  props:{
    gallery:{
      type: Object,
    },
  },
  mounted() {
    let options = {
      threshold: 0.50
    }
    this.observer = new IntersectionObserver(entries => {
      const image = entries[0];
      if (image.isIntersecting) {
        this.inViewClass = 'active';
        this.$root.verticalCurrent = this.$vnode.key;
        this.$root.gallerySelected = image.target.id
      }
      else{
        this.inViewClass = 'not-active';
      }
    }, options);
    this.observer.observe(this.$el);
  },
  computed:{
    classes(){
      let classesReturn = [];
      classesReturn.push('viewport-observer');
      classesReturn.push('vertical-slide');
      classesReturn.push('fade-in');
      classesReturn.push((this.$root.showGallery) ? 'show-gallery' : '');
      classesReturn.push(this.inViewClass);
      return classesReturn.join(' ');
    },
    styles(){
      return {
        backgroundImage: `url('${this.thumbUrl(this.gallery.hero.file)}')`, 
        backgroundPosition:this.gallery.hero.position, 
        color: this.$root.colors.numeral
      }
    }
  },
  methods:{
    thumbUrl(filename) {
      return require("@/" + filename);
    },
    horzontalSlide(){
      this.$root.showGallery = true;
      this.$root.horizontalCurrent++;
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "@/globals.scss";

  .vertical-slide{
    // transition: all 0.3s ease-in-out;
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    left: 0px;
    right: 0px;
    cursor: pointer;
    margin: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    @media #{$mq-desktop-only} {
      scroll-snap-align: start;
      &:not(.show-gallery){
        // &:before{
        //   transition: opacity 0.5s ease-in-out;
        //   opacity: 0;
        //   content: 'Click to continue';
        //   color: #fff;
        //   display: flex;
        //   align-items: flex-end;
        //   justify-content: center;
        //   width: 100vw;
        //   position: relative;
        //   bottom: 20px;
        //   text-transform: uppercase;
        //   font:{
        //     size: 12px;
        //   }
        //   letter-spacing: 1px;
        // }
        // &:hover{
        //   &:before{
        //     opacity: 0.5;
        //   }
        // }
      }
    }
    &.collapse{
      width: 50vw;
      left: 0%;
    }
    &.active{
      box-shadow: 0px 0px 40px rgba(0,0,0,0.4);
      z-index: 100;
    }
    &.viewport-observer{
      background: {
        position: center;
        size: cover;
        repeat: no-repeat;
      }
      cursor: pointer;
      &.slide-down{
        transition: all 0.8s ease-in-out;
        &.not-active{
          transform: translateY(-50px);
        }
        &.active{
          transform: translateY(0px);
        }
      }
      &.scale-in{
        transition: all 0.8s ease-in-out;
        &.not-active{
          transform: scale(0.90);
        }
        &.active{
          transform: scale(1);
        }
      }
      &.scale-out{
        transition: all 0.8s ease-in-out;
        &.not-active{
          transform: scale(1.25);
        }
        &.active{
          transform: scale(1);
        }
      }
      &.fade-in{
        transition: all 0.6s ease-in-out;
        &.not-active{
          opacity: 0.6;
        }
        &.active{
          opacity: 1;
        }
      }
    }
  }
</style>
