<template>
  <div id="app" :class="classes">
    <div id="preload">
      <img :src="require('@/assets/home/image-01.jpg')">
      <img :src="require('@/assets/home/image-02.jpg')">
      <img :src="require('@/assets/home/image-03.jpg')">
      <img :src="require('@/assets/home/image-04.jpg')">
      <img :src="require('@/assets/home/image-05.jpg')">
      <img :src="require('@/assets/home/image-06.jpg')">
      <img :src="require('@/assets/home/image-07.jpg')">
    </div>
    <div v-show="!$root.hideArrows" :style="$root.arrowStyles" :class="['chevron',$root.arrowDirection]"></div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      y: 0,
      loaded: false,
    }
  },
  
  created(){
    let self = this;
    window.addEventListener('load', function () {
      self.loaded = true;
    })
  },
  computed:{
    fromTop(){
      return this.y+'px';
    },
    classes(){
      let classesReturn = [];
      classesReturn.push(this.$root.appClass);
      if(this.loaded){
        classesReturn.push('loaded');
      }
      return classesReturn.join(' ');
    }
  },
}
</script>

<style lang="scss">
@import "@/globals.scss";

#preload{
  width: 1px;
  height: 1px;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  position: absolute;
  top: -100px;
}

html{
  height: 100%;
  scroll-behavior: smooth;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  // background-color: #000;
  body{
    height: 100%;
    margin: 0 0 50px 0;
    font-family: 'Nunito Sans', sans-serif;
    overscroll-behavior: none;
    // background-color: #000;
    h1,h2,h3,h4,h5,h6{
      font-family: 'Nunito Sans', sans-serif;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-top: 0;
    }
    p{
      font-size: 12px;
      line-height: 18px;
      color: #8f8f8f;
      margin-bottom: 20px;
      letter-spacing: 1px;
    }
    a{
      transition: color 0.3s linear;
      color: #333;
      letter-spacing: 1px;
      &:hover{
        color: #666;
      }
    }
    img{
      max-width: 100%;
      height: auto;
    }
    #app{
      height: 100%;
      &:not(.loaded){
        &:before{
          content: 'Loading...';
          position: absolute;
          top: 45%;
          left: 45%;
          color: #fff;
        }
      }
      .hide-on-mobile{
        @media (hover: none) {
          display: none;
        }
      }
      &.dont-scroll{
        overflow: hidden;
      }
      &.template-standard{
        background-color: #f8f8f8;
      }
      &.template-internal{
        background-color: #fff;
      }
      .viewport-transiton{
        transition: all 0.5s linear;
      }
      .content{
        max-width: 960px;
        margin: auto;
        padding: 50px 0px;
        h1{
          text-align: center;
          margin-bottom: 50px;
        }
        p{
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

</style>


