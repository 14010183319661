<template>
  <div class="content">
    <h1>404</h1>
    <h4>You have arrived here by mistake.</h4>
    <a href="/">Go Home</a>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {

    }
  },
  created() {

  }
}
</script>

<style lang="scss" scoped>
  .content{
    width: 100%;
    padding: 0px 50px;
    text-align: center;
    h1,h4{
      margin: 0;
      margin-bottom: 100px;
    }
  }
</style>
